import React from "react";
import { Container, Navbar } from "react-bootstrap";
import eLderlylife_logo from "../assets/elderlylife-logo.svg";

const Logoheader = () => {
  return (
    <Navbar className="elderly text-center" bg="elderly">
      <Container>
        <Navbar.Brand href="/" className="d-block w-100 m-0">
          <img
            src={eLderlylife_logo}
            className="d-inline-block align-top"
            alt="React Bootstrap logo"
          />
        </Navbar.Brand>
      </Container>
    </Navbar>
  );
};

export default Logoheader;
