import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../../../Components/header/Header";
import Footer from "../../../Components/footer/Footer";
import Loader from "../../loader/Loader";

const CookiePolicy = () => {
  const [loading, setloading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setloading(false);
    }, 500);
  }, []);

  window.intercomSettings = {
    api_base: "https://api-iam.intercom.io",
    app_id: "nedui962",
  };
  // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/nedui962'
  (function () {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === "function") {
      ic("reattach_activator");
      ic("update", w.intercomSettings);
    } else {
      var d = document;
      var i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      var l = function () {
        var s = d.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = "https://widget.intercom.io/widget/nedui962";
        var x = d.getElementsByTagName("script")[0];
        x.parentNode.insertBefore(s, x);
      };
      if (document.readyState === "complete") {
        l();
      } else if (w.attachEvent) {
        w.attachEvent("onload", l);
      } else {
        w.addEventListener("load", l, false);
      }
    }
  })();
  return (
    <>
      <Header />
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="hero">
            <Container>
              <Row className="justify-content-center">
                <Col xl="17">
                  <h1 className="text-center mb-0">Cookie policy</h1>
                </Col>
              </Row>
            </Container>
          </div>
          <Container>
            <Row className="justify-content-center mb-11 mb-md-12 mb-xxl-13 mb-xxxl-14">
              <Col xl="18" xxl="16" xxxl="13">
                <h2 class="h5">What are cookies</h2>
                <p>
                  As is common practice with almost all professional websites
                  this site uses cookies, which are tiny files that are
                  downloaded to your computer, to improve your experience. This
                  page describes what information they gather, how we use it and
                  why we sometimes need to store these cookies. We will also
                  share how you can prevent these cookies from being stored
                  however this may downgrade or 'break' certain elements of the
                  sites functionality.
                </p>
                <p class="mb-7">
                  For more general information on cookies visit{" "}
                  <a
                    href="https://cookiesandyou.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Cookies &amp; You
                  </a>{" "}
                  or see the Wikipedia article on{" "}
                  <a
                    href="https://en.wikipedia.org/wiki/HTTP_cookie"
                    target="_blank"
                    rel="noreferrer"
                  >
                    HTTP Cookies
                  </a>
                  .
                </p>
                <h2 class="h5">How we use cookies</h2>
                <p class="mb-7">
                  We use cookies for a variety of reasons detailed below.
                  Unfortunately in most cases there are no industry standard
                  options for disabling cookies without completely disabling the
                  functionality and features they add to this site. It is
                  recommended that you leave on all cookies if you are not sure
                  whether you need them or not in case they are used to provide
                  a service that you use.
                </p>

                <h2 class="h5">Disabling cookies</h2>
                <p class="mb-7">
                  You can prevent the setting of cookies by adjusting the
                  settings on your browser (see your browser Help for how to do
                  this). Be aware that disabling cookies will affect the
                  functionality of this and many other websites that you visit.
                  Disabling cookies will usually result in also disabling
                  certain functionality and features of the this site. Therefore
                  it is recommended that you do not disable cookies.
                </p>

                <h2 class="h5 mb-5">The cookies we set</h2>
                <section>
                  <h3 class="h6">Login related cookies</h3>
                  <p class="mb-7">
                    We use cookies when you are logged in so that we can
                    remember this fact. This prevents you from having to log in
                    every single time you visit a new page. These cookies are
                    typically removed or cleared when you log out to ensure that
                    you can only access restricted features and areas when
                    logged in.
                  </p>
                </section>
                <section>
                  <h3 class="h6">Site preferences cookies</h3>
                  <p class="mb-7">
                    In order to provide you with a great experience on this site
                    we provide the functionality to set your preferences for how
                    this site runs when you use it. In order to remember your
                    preferences we need to set cookies so that this information
                    can be called whenever you interact with a page is affected
                    by your preferences.
                  </p>
                </section>
                <section>
                  <h3 class="h6">Form-related cookies</h3>
                  <p class="mb-7">
                    When you submit data to through a form such as those found
                    on contact pages or comment forms cookies may be set to
                    remember your user details for future correspondence.
                  </p>
                </section>
                <section>
                  <h3 class="h6">Third party cookies</h3>
                  <p class="mb-7">
                    In some special cases we also use cookies provided by
                    trusted third parties. The following section details which
                    third party cookies you might encounter through this site.
                  </p>
                  <section>
                    <h4 class="h6">Google Analytics</h4>
                    <p>
                      This site uses Google Analytics which is one of the most
                      widespread and trusted analytics solution on the web for
                      helping us to understand how you use the site and ways
                      that we can improve your experience. These cookies may
                      track things such as how long you spend on the site and
                      the pages that you visit so we can continue to produce
                      engaging content.
                    </p>
                    <p class="mb-7">
                      For more information on Google Analytics cookies, see the
                      official{" "}
                      <a
                        href="https://analytics.google.com/analytics/web/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Google Analytics
                      </a>{" "}
                      page.
                    </p>
                  </section>
                </section>
                <h2 class="h5">More information</h2>
                <p>
                  Hopefully that has clarified things for you and as was
                  previously mentioned if there is something that you aren’t
                  sure whether you need or not it’s usually safer to leave
                  cookies enabled in case it does interact with one of the
                  features you use on our site. This cookies policy was created
                  with the help of the{" "}
                  <a
                    href="https://cookiepolicygenerator.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    GDPR Cookies Policy Generator
                  </a>
                  .
                </p>
                <p class="mb-0">
                  However if you are still looking for more information then you
                  can contact us via email at{" "}
                  <a href="mailto:hello@elfderlylife.com">
                    hello@elfderlylife.com
                  </a>
                  .
                </p>
              </Col>
            </Row>
          </Container>
          <Footer />
        </>
      )}
    </>
  );
};

export default CookiePolicy;
