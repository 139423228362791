import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Image } from "react-bootstrap";
import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import Loader from "../../loader/Loader";
import SupportUserSection from "../../templates/SupportUserSection";
import UspSection from "../../templates/UspSection";
import PricingPlanCallToAction from "../../templates/PricingPlanCallToAction";
import HeroGraphic from "../../../assets/images/outgoings/outgoings-screenshot.jpg";
import PageCallToAction from "../PageCallToAction";
import screenie1 from "../../../assets/images/index/screenshots/screenie-1.jpg";
import screenie2 from "../../../assets/images/index/screenshots/screenie-2.jpg";
import screenie3 from "../../../assets/images/index/screenshots/screenie-3.jpg";
import grandMotherAndGrandson from "../../../assets/images/index/grandmother-and-grandson.jpg";
import quoteZoneLogo from "../../../assets/images/third-party-logos/quote-zone-logo.png";
import giftCard1 from "../../../assets/images/gift-cards/life-style-gift-card.jpg";
import giftCard2 from "../../../assets/images/gift-cards/john-lewis-gift-card.jpg";
import giftCard3 from "../../../assets/images/gift-cards/river-island-gift-card.jpg";
import giftCard4 from "../../../assets/images/gift-cards/one4all-gift-card.jpg";
import giftCard5 from "../../../assets/images/gift-cards/boots-gift-card.jpg";
import giftCard6 from "../../../assets/images/gift-cards/m-and-s-gift-card.jpg";
import TestimonialsSlider from "../../TestimonialsSlider/TestimonialsSlider";
import sonCookingWithElderlyFather from "../../../assets/images/index/son-cooking-with-elderly-father.jpg";

const HowItWorks = () => {
  const [loading, setloading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setloading(false);
    }, 500);
  }, []);

  return (
    <>
      <Header />
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="hero">
            <Container>
              <Row className="justify-content-between gx-xxl-0">
                <Col xl="13" xxl="12" xxxl="10">
                  <h1 className="h2 mb-5 mb-md-6 pt-xl-10 pt-xxxl-11">
                    Manage the finances yourself, or together
                  </h1>
                  <p className="lead mb-7">
                    Manage your outgoings, save on your bills, set reminders,
                    get discounts, find exclusive offers, so you can focus on
                    living.
                  </p>
                  <Button href="/plans" size="lg">
                    Sign up free
                  </Button>
                </Col>
                <Col xl="10" xxl="11" className="offset-xl-1 mt-5">
                  <Image src={HeroGraphic} className="img-fluid" alt="" />
                </Col>
              </Row>
            </Container>
          </div>
          <Container>
            <section className="pb-11 pb-md-12 pt-xl-1 pb-xl-13 pt-xxxl-11 pb-xxxl-14">
              <Row className="justify-content-center mb-10 mb-md-11 mb-xl-12 mb-xxxl-13">
                <Col xl="20" xxxl="16">
                  <h2 className="text-center mb-5 mb-md-6">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </h2>
                  <p className="lead text-center mb-0">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nunc lobortis bibendum euismod. Phasellus ullamcorper
                    rhoncus vestibulum.
                  </p>
                </Col>
              </Row>
              <Row className="align-items-center gy-5 gy-md-7 gy-xl-0 pb-10 pb-md-11 pb-xl-12 pb-xxxl-13">
                <Col>
                  <Image
                    src={sonCookingWithElderlyFather}
                    className="img-object-fit-cover rounded-4"
                    alt="Son cooking with elderly father"
                  />
                </Col>
                <Col
                  xl="14"
                  xxl="13"
                  xxxl="12"
                  className="offset-xl-1 offset-xxl-2"
                >
                  <i className="fa fa-solid fa-1 text-primary fs-3 lh-base mb-2"></i>
                  <h3 className="mb-5">Lorem ipsum</h3>
                  <p className="lead mb-0">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nunc lobortis bibendum euismod. Phasellus ullamcorper
                    rhoncus vestibulum.
                  </p>
                </Col>
              </Row>
              <Row className="align-items-center gy-5 gy-md-7 gy-xl-0 pb-10 pb-md-11 pb-xl-12 pb-xxxl-13">
                <Col className="order-xl-last offset-xl-1 offset-xxl-2">
                  <Image
                    src={sonCookingWithElderlyFather}
                    className="img-object-fit-cover rounded-4"
                    alt="Son cooking with elderly father"
                  />
                </Col>
                <Col xl="14" xxl="13" xxxl="12">
                  <i className="fa fa-solid fa-2 text-primary fs-3 lh-base mb-2"></i>
                  <h3 className="mb-5">Lorem ipsum</h3>
                  <p className="lead mb-0">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nunc lobortis bibendum euismod. Phasellus ullamcorper
                    rhoncus vestibulum.
                  </p>
                </Col>
              </Row>
              <Row className="align-items-center gy-5 gy-md-7 gy-xl-0">
                <Col>
                  <Image
                    src={sonCookingWithElderlyFather}
                    className="img-object-fit-cover rounded-4"
                    alt="Son cooking with elderly father"
                  />
                </Col>
                <Col
                  xl="14"
                  xxl="13"
                  xxxl="12"
                  className="offset-xl-1 offset-xxl-2"
                >
                  <i className="fa fa-solid fa-3 text-primary fs-3 lh-base mb-2"></i>
                  <h3 className="mb-5">Lorem ipsum</h3>
                  <p className="lead mb-0">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nunc lobortis bibendum euismod. Phasellus ullamcorper
                    rhoncus vestibulum.
                  </p>
                </Col>
              </Row>
            </section>
            <section className="pb-11 pb-md-12 pb-xl-13 pb-xxxl-14">
              <Row className="justify-content-center mb-10 mb-md-11 mb-xl-12">
                <Col xl="20" xxxl="17">
                  <h2 className="text-center mb-5 mb-md-6">
                    It’s about time somebody used that fancy tech stuff to
                    reduce headaches.
                  </h2>
                  <p className="lead text-center mb-0">
                    Elderly Life harmonises the boring things in life so you can
                    focus on the important things.
                  </p>
                </Col>
              </Row>
              <Row className="row-cols-1 row-cols-md-2 row-cols-xl-4 justify-content-center gy-5 gx-xxxl-6">
                <Col>
                  <div className="bg-primary-light rounded-3 p-5 p-xl-6 p-xxxl-7 h-100">
                    <Row className="justify-content-center mb-7">
                      <Col xl="21" xxxl="18">
                        <h6 className="text-center mb-3">
                          Track every penny, from bills to Netflix
                        </h6>
                        <p className="small text-dark text-center mb-0">
                          Connect all your outgoings for hundreds of brands.
                        </p>
                      </Col>
                    </Row>
                    <Image
                      src={sonCookingWithElderlyFather}
                      className="img-object-fit-cover rounded-4 h-auto"
                      alt="Son cooking with elderly father"
                    />
                  </div>
                </Col>
                <Col>
                  <div className="bg-primary-light rounded-3 p-5 p-xl-6 p-xxxl-7 h-100">
                    <Row className="justify-content-center mb-7">
                      <Col xl="21" xxxl="18">
                        <h6 className="text-center mb-3">
                          Save money with Compare & Save
                        </h6>
                        <p className="small text-dark text-center mb-0">
                          Find better deals with our price comparison tool.
                        </p>
                      </Col>
                    </Row>
                    <Image
                      src={screenie2}
                      className="img-fluid rounded-3 shadow mb-3"
                      alt=""
                    />
                    <div className="d-flex justify-content-center align-items-center">
                      <div className="small-xs fw-semibold text-center">
                        Powered by
                      </div>
                      <Image
                        src={quoteZoneLogo}
                        height="16"
                        className="ms-2"
                        alt=""
                      />
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="bg-primary-light rounded-3 p-5 p-xl-6 p-xxxl-7 h-100">
                    <Row className="justify-content-center mb-7">
                      <Col xl="22" xxxl="19">
                        <h6 className="text-center mb-3">
                          Never forget another birthday present
                        </h6>
                        <p className="small text-dark text-center mb-0">
                          Set reminders for the theatre tickets, birthday’s and
                          more.
                        </p>
                      </Col>
                    </Row>
                    <div className="me-9 mb-5">
                      <Image
                        src={screenie3}
                        className="img-fluid rounded-3 shadow"
                        alt=""
                      />
                    </div>
                    <div className="ms-9 mt-4">
                      <Image
                        src={grandMotherAndGrandson}
                        className="img-fluid rounded-3 shadow"
                        alt=""
                      />
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="bg-primary-light rounded-3 p-5 p-xl-6 p-xxxl-7 h-100">
                    <Row className="justify-content-center mb-7">
                      <Col xl="23" xxxl="19">
                        <h6 className="text-center mb-3">
                          Keep track of gift cards and warranties
                        </h6>
                        <p className="small text-dark text-center mb-0">
                          Never let a gift card, voucher or warranty expire
                          again.
                        </p>
                      </Col>
                    </Row>
                    <Row className="justify-content-center gy-5 gx-xxxl-6 gy-xxxl-6">
                      <Col xs="auto">
                        <Image
                          src={giftCard1}
                          className="img-gift-card shadow rounded-1"
                          alt=""
                        />
                      </Col>
                      <Col xs="auto">
                        <Image
                          src={giftCard2}
                          className="img-gift-card shadow rounded-1"
                          alt=""
                        />
                      </Col>
                      <Col xs="auto">
                        <Image
                          src={giftCard3}
                          className="img-gift-card shadow rounded-1"
                          alt=""
                        />
                      </Col>
                      <Col xs="auto">
                        <Image
                          src={giftCard4}
                          className="img-gift-card shadow rounded-1"
                          alt=""
                        />
                      </Col>
                      <Col xs="auto">
                        <Image
                          src={giftCard5}
                          className="img-gift-card shadow rounded-1"
                          alt=""
                        />
                      </Col>
                      <Col xs="auto">
                        <Image
                          src={giftCard6}
                          className="img-gift-card shadow rounded-1"
                          alt=""
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </section>
            <SupportUserSection />
            <UspSection />
          </Container>
          <TestimonialsSlider />
          <PricingPlanCallToAction />
          <PageCallToAction messageContent="Make life more simple today." />
          <Footer />
        </>
      )}
    </>
  );
};

export default HowItWorks;
