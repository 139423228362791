import React from "react";
import { Row, Col, Button, Image, Badge} from "react-bootstrap";
import dad from '../../assets/images/index/dad.jpg';
import mum from '../../assets/images/index/mum.jpg';
import daughter from '../../assets/images/index/daughter.jpg';
import logoIcon from "../../assets/images/logo-icon.svg";

const SupportUserSection = () => {

  return (
    <section className="pb-11 pb-md-12 pb-xl-13 pb-xxxl-14 position-relative">
      <Row className="justify-content-center mb-10 mb-md-11 mb-xl-12">
        <Col xl="20" xxxl="17">
          <h2 className="text-center mb-5 mb-md-6">Don’t be alone in dealing with everything life throws at you</h2>
          <p className="lead text-dark text-center mb-0">Don’t feel overwhelmed by the bills and fast-moving world – add a support user to help you.</p>
        </Col>
      </Row>
      <div className="usp-section-bg-container d-flex justify-content-center align-items-center position-absolute start-50 translate-middle w-100 h-100 mt-14">
        <div className="circle-1 border rounded-circle position-absolute"></div>
        <div className="circle-2 border rounded-circle position-absolute"></div>
        <div className="circle-3 border rounded-circle position-absolute"></div>
      </div>
      <Row className="usp-section justify-content-center position-relative gx-5 gx-md-7 gx-xl-9 gx-xxxl-10">
        <Col xs="auto" className="d-flex flex-column align-items-center">
          <div className="img-avatar mb-4">
            <Image src={dad} className="img-object-fit-cover" alt="" />
          </div>
          <div className="fw-bold text-dark">Dad</div>
          <div>Main user</div>
        </Col>
        <Col xs="auto" className="d-flex justify-content-center align-items-end">
          <Image src={logoIcon} className="d-md-none mb-7" height="33" alt="" />
          <Image src={logoIcon} className="d-none d-md-block d-xxxl-none mb-7" height="40" alt="" />
          <Image src={logoIcon} className="d-none d-xxxl-block mb-7" height="48" alt="" />
        </Col>
        <Col xs="auto" className="d-flex flex-column align-items-center">
          <div className="img-avatar mb-4">
            <Image src={mum} className="img-object-fit-cover" alt="" />
          </div>
          <div className="fw-bold text-dark">Mum</div>
          <div>Main user</div>
        </Col>
        <Col xs="auto" className="d-flex flex-column align-items-center position-absolute support-user">
          <div className="img-avatar mb-4">
            <Image src={daughter} className="img-object-fit-cover" alt="" />
          </div>
          <div className="fw-bold text-dark">Daughter</div>
          <div>Support user</div>
        </Col>
        <Col className="mt-13 position-absolute">
          <ul className="list-unstyled">
            <li className="badge-1 d-md-flex flex-column align-items-center position-absolute d-none">
              <Badge bg="primary-light d-flex justify-content-center rounded-circle p-3 mb-2">
                <i className="fa-solid fa-fire-flame-simple fa-xl lh-1 text-info"></i>
              </Badge>
              <div className="small-xs">Gas</div>
            </li>
            <li className="badge-2 d-flex flex-column align-items-center position-absolute">
              <Badge bg="primary-light d-flex justify-content-center rounded-circle p-3 mb-2">
                <i className="fa-solid fa-receipt fa-xl lh-1 text-info"></i>
              </Badge>
              <div className="small-xs">Bills</div>
            </li>
            <li className="badge-3 d-sm-flex flex-column align-items-center position-absolute d-none">
              <Badge bg="primary-light d-flex justify-content-center rounded-circle p-3 mb-2">
                <i className="fa-solid fa-home fa-xl lh-1 text-info"></i>
              </Badge>
              <div className="small-xs">Home insurance</div>
            </li>
            <li className="badge-4 d-flex flex-column align-items-center position-absolute">
              <Badge bg="primary-light d-flex justify-content-center rounded-circle p-3 mb-2">
                <i className="fa-solid fa-stopwatch fa-xl lh-1 text-info"></i>
              </Badge>
              <div className="small-xs">Appointments</div>
            </li>
            <li className="badge-5 d-sm-flex flex-column align-items-center position-absolute d-none">
              <Badge bg="primary-light d-flex justify-content-center rounded-circle p-3 mb-2">
                <i className="fa-solid fa-bolt fa-xl lh-1 text-info"></i>
              </Badge>
              <div className="small-xs">Electric</div>
            </li>
            <li className="badge-6 d-md-flex flex-column align-items-center position-absolute d-none">
              <Badge bg="primary-light d-flex justify-content-center rounded-circle p-3 mb-2">
                <i className="fa-solid fa-champagne-glasses fa-xl lh-1 text-info"></i>
              </Badge>
              <div className="small-xs">Events</div>
            </li>
            <li className="badge-7 d-flex flex-column align-items-center position-absolute">
              <Badge bg="primary-light d-flex justify-content-center rounded-circle p-3 mb-2">
                <i className="fa-solid fa-percent fa-xl lh-1 text-info"></i>
              </Badge>
              <div className="small-xs">Vouchers</div>
            </li>
            <li className="badge-8 d-flex flex-column align-items-center position-absolute">
              <Badge bg="primary-light d-flex justify-content-center rounded-circle p-3 mb-2">
                <i className="fa-solid fa-gift fa-xl lh-1 text-info"></i>
              </Badge>
              <div className="small-xs">Gift cards</div>
            </li>
            <li className="badge-9 d-flex flex-column align-items-center position-absolute">
              <Badge bg="primary-light d-flex justify-content-center rounded-circle p-3 mb-2">
                <i className="fa-solid fa-shield fa-xl lh-1 text-info"></i>
              </Badge>
              <div className="small-xs">Warranties</div>
            </li>
            <li className="badge-10 d-md-flex flex-column align-items-center position-absolute d-none">
              <Badge bg="primary-light d-flex justify-content-center rounded-circle p-3 mb-2">
                <i className="fa-solid fa-heart fa-xl lh-1 text-info"></i>
              </Badge>
              <div className="small-xs">Health insurance</div>
            </li>
            <li className="badge-11 d-sm-flex flex-column align-items-center position-absolute d-none">
              <Badge bg="primary-light d-flex justify-content-center rounded-circle p-3 mb-2">
                <i className="fa-solid fa-cake-candles fa-xl lh-1 text-info"></i>
              </Badge>
              <div className="small-xs">Birthdays</div>
            </li>
            <li className="badge-12 d-md-flex flex-column align-items-center position-absolute d-none">
              <Badge bg="primary-light d-flex justify-content-center rounded-circle p-3 mb-2">
                <i className="fa-solid fa-car-side fa-xl lh-1 text-info"></i>
              </Badge>
              <div className="small-xs">Car insurance</div>
            </li>
            <li className="badge-13 d-flex flex-column align-items-center position-absolute">
              <Badge bg="primary-light d-flex justify-content-center rounded-circle p-3 mb-2">
                <i className="fa-solid fa-ticket fa-xl lh-1 text-info"></i>
              </Badge>
              <div className="small-xs">Coupons</div>
            </li>
          </ul>
        </Col>
      </Row>
      <div className="text-center mt-11">
        <Button href="/plans" size="lg">Try free for 7 days</Button>
      </div>
    </section>
  )
};

export default SupportUserSection;
