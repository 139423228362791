import React from 'react'
import { Row, Col, Image, Container } from "react-bootstrap";
// import Swiper JS
import { Autoplay, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
// import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import './TestimonialsSlider.scss';

import familyAroundTabletXL from '../../assets/images/index/ray-shah.jpg';

const TestimonialsSlider = () => {
  return (
    <section className="testimonials-slider pb-11 pb-md-12 pb-xl-13 pb-xxxl-14">
      <Container className="text-center mb-10 mb-md-11 mb-xl-12">
        <i className="fa fa-solid fa-message fs-2 text-emphasize mb-3"></i>
        <h2 className="text-emphasize mb-0">Loved is an understatement</h2>
      </Container>
      <Swiper
        modules={[Autoplay, Pagination]}
        spaceBetween={32}
        slidesPerView={1.3}
        centeredSlides={true}
        loop={true}
        pagination={{
          clickable: true
        }}
        autoplay={{
          delay: 4000
        }}
        sli
        wrapperClass='mb-9'
      >
        <SwiperSlide>
          <Row className="justify-content-xl-center">
            <Col>
              <div className="bg-emphasize-light rounded-3 p-7 p-md-10 p-xl-12 p-xxxl-13">
                <Row className="justify-content-lg-center gy-6 gy-md-8 gx-lg-10 gy-lg-0 gx-xl-11 gx-xxxl-12">
                  <Col lg="auto">
                    <div className="img-avatar img-avatar-lg mt-lg-12">
                      <Image src={familyAroundTabletXL} className="img-object-fit-cover" alt="" />
                    </div>
                  </Col>
                  <Col lg="16" xl="15" xxxl="14">
                    <i className="fa fa-solid fa-quote-left fs-5 text-emphasize mb-5 mb-md-6"></i>
                    <p className="fs-6 text-dark mb-6 mb-md-7">I was stuck in a cycle of contract renewal out of convenience.</p>
                    <p className="fs-6 text-dark mb-5 mb-md-6">Elderly Life helped me save money and time, meaning I can focus on the important things more – like my golf handicap.</p>
                    <p className="lead text-dark fw-semibold mb-1">Ray Shah</p>
                    <p className="small text-dark mb-0">Father of three</p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </SwiperSlide>
        <SwiperSlide>
          <Row className="justify-content-xl-center">
            <Col>
              <div className="bg-emphasize-light rounded-3 p-7 p-md-10 p-xl-12 p-xxxl-13">
                <Row className="justify-content-lg-center gy-6 gy-md-8 gx-lg-10 gy-lg-0 gx-xl-11 gx-xxxl-12">
                  <Col lg="auto">
                    <div className="img-avatar img-avatar-lg mt-lg-12">
                      <Image src={familyAroundTabletXL} className="img-object-fit-cover" alt="" />
                    </div>
                  </Col>
                  <Col lg="16" xl="15" xxxl="14">
                    <i className="fa fa-solid fa-quote-left fs-5 text-emphasize mb-5 mb-md-6"></i>
                    <p className="fs-6 text-dark mb-6 mb-md-7">I was stuck in a cycle of contract renewal out of convenience.</p>
                    <p className="fs-6 text-dark mb-5 mb-md-6">Elderly Life helped me save money and time, meaning I can focus on the important things more – like my golf handicap.</p>
                    <p className="lead text-dark fw-semibold mb-1">Ray Shah</p>
                    <p className="small text-dark mb-0">Father of three</p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </SwiperSlide>
        <SwiperSlide>
          <Row className="justify-content-xl-center">
            <Col>
              <div className="bg-emphasize-light rounded-3 p-7 p-md-10 p-xl-12 p-xxxl-13">
                <Row className="justify-content-lg-center gy-6 gy-md-8 gx-lg-10 gy-lg-0 gx-xl-11 gx-xxxl-12">
                  <Col lg="auto">
                    <div className="img-avatar img-avatar-lg mt-lg-12">
                      <Image src={familyAroundTabletXL} className="img-object-fit-cover" alt="" />
                    </div>
                  </Col>
                  <Col lg="16" xl="15" xxxl="14">
                    <i className="fa fa-solid fa-quote-left fs-5 text-emphasize mb-5 mb-md-6"></i>
                    <p className="fs-6 text-dark mb-6 mb-md-7">I was stuck in a cycle of contract renewal out of convenience.</p>
                    <p className="fs-6 text-dark mb-5 mb-md-6">Elderly Life helped me save money and time, meaning I can focus on the important things more – like my golf handicap.</p>
                    <p className="lead text-dark fw-semibold mb-1">Ray Shah</p>
                    <p className="small text-dark mb-0">Father of three</p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </SwiperSlide>
        <SwiperSlide>
          <Row className="justify-content-xl-center">
            <Col>
              <div className="bg-emphasize-light rounded-3 p-7 p-md-10 p-xl-12 p-xxxl-13">
                <Row className="justify-content-lg-center gy-6 gy-md-8 gx-lg-10 gy-lg-0 gx-xl-11 gx-xxxl-12">
                  <Col lg="auto">
                    <div className="img-avatar img-avatar-lg mt-lg-12">
                      <Image src={familyAroundTabletXL} className="img-object-fit-cover" alt="" />
                    </div>
                  </Col>
                  <Col lg="16" xl="15" xxxl="14">
                    <i className="fa fa-solid fa-quote-left fs-5 text-emphasize mb-5 mb-md-6"></i>
                    <p className="fs-6 text-dark mb-6 mb-md-7">I was stuck in a cycle of contract renewal out of convenience.</p>
                    <p className="fs-6 text-dark mb-5 mb-md-6">Elderly Life helped me save money and time, meaning I can focus on the important things more – like my golf handicap.</p>
                    <p className="lead text-dark fw-semibold mb-1">Ray Shah</p>
                    <p className="small text-dark mb-0">Father of three</p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </SwiperSlide>
      </Swiper>
    </section>
  )
}

export default TestimonialsSlider;