import React from 'react';
import { Navbar, Container, Offcanvas, Nav, Button, NavDropdown, Dropdown } from "react-bootstrap";
import Logo from "../../assets/images/logo-icon.svg";

const NavBar = () => {
  const splitUrl = window.location.href.split("/");
  console.log(splitUrl);

  return (
    <Navbar key="xl" expand="xl">
      <Container>
        <Navbar.Brand href="/home"><img src={Logo} alt="Eldery Life logo" /></Navbar.Brand>
        <Navbar.Toggle aria-controls="offcanvasNavbar-expand-xl" />
        <Navbar.Offcanvas
          id="offcanvasNavbar-expand-xl"
          aria-labelledby="offcanvasNavbarLabel-expand-xl"
        >
          <Offcanvas.Header closeButton>
            <Navbar.Brand href="/home"><img src={Logo} alt="Eldery Life logo" /></Navbar.Brand>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="align-items-xl-center flex-grow-1">
              <div className="nav-item">
                <Nav.Link href="/home" className={splitUrl[3] === "home" ? "active" : ""}>Home</Nav.Link>
              </div>
              <div className="nav-item">
                <Nav.Link href="/how-it-works" className={splitUrl[3] === "how-it-works" ? "active" : ""}>How it works</Nav.Link>
              </div>
              <div className="nav-item">
                <Nav.Link href="/our-mission" className={splitUrl[3] === "our-mission" ? "active" : ""}>Our mission</Nav.Link>
              </div>
              <div className="nav-item">
                <Nav.Link href="/plans" className={splitUrl[3] === "plans" ? "active" : ""}>Plans & pricing</Nav.Link>
              </div>
              <div className="nav-item mb-7 mb-xl-0">
                <Nav.Link href="#" className={splitUrl[3] === "" ? "active" : ""}>Support</Nav.Link>
              </div>
              <Button href="https://dev.elderlylife.co.uk/" className="btn-primary-light fw-normal w-100 mb-5 d-xl-none" target="_blank">Login</Button>
              <Button href="/plans" className="w-100 d-xl-none">Sign up free</Button>
              <div className="ms-auto d-none d-xl-block">
                <Button href="https://dev.elderlylife.co.uk/" className="btn-primary-light fw-normal px-7" target="_blank">Login</Button>
              </div>
              <Button href="/plans" className="ms-4 ms-xxxl-5 d-none d-xl-block">Sign up free</Button>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
}

export default NavBar;
