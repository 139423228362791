import { Button, Card } from "react-bootstrap";

const PlanCard = ({ planType }) => {

  return (
    <Card className="border-0 shadow position-relative overflow-hidden">
      {planType === 'family' && <span className="card-ribbon h6 small-xs text-center py-2 mb-0">Best value</span>}
      <Card.Body>
        <h3 className="h6 mb-5 mb-md-6">
          {planType === 'single' ? (
            <span>Single</span>
          ) : planType === 'couple' ? (
            <span>Couple</span>
          ) : planType === 'family' ? (
            <span>Family</span>
          ) : null }
        </h3>
        <div className="fs-4 text-info fw-bold lh-sm mb-0">
          {planType === 'single' ? (
            <span>£3.99</span>
          ) : planType === 'couple' ? (
            <span>£5.99</span>
          ) : planType === 'family' ? (
            <span>£7.99</span>
          ) : null }
        </div>
        <div className="small-xs text-dark mb-4 mb-md-5">per month</div>
        <ul className="list-unstyled list-checked small text-dark mb-5 mb-md-6">
          <li className="list-item d-flex align-items-center mb-1">
            <i className="fa fa-solid fa-check text-success"></i>
            {planType === 'single' ? (
              <span className="ms-3">1 main user</span>
            ) : planType === 'couple' ? (
              <span className="ms-3">2 main users</span>
            ) : planType === 'family' ? (
              <span className="ms-3">5 main users</span>
            ) : null }
          </li>
          <li className="list-item d-flex align-items-center mb-1">
            <i className="fa fa-solid fa-check text-success"></i>
            {planType === 'single' ? (
              <span className="ms-3">1 support user</span>
            ) : planType === 'couple' ? (
              <span className="ms-3">2 support users</span>
            ) : planType === 'family' ? (
              <span className="ms-3">5 support users</span>
            ) : null }
          </li>
          <li className="list-item d-flex align-items-center mb-1">
            <i className="fa fa-solid fa-check text-success"></i>
            <span className="ms-3">Cancel any time</span>
          </li>
          <li className="list-item d-flex align-items-center">
            <i className="fa fa-solid fa-check text-success"></i>
            <span className="ms-3">No hidden costs</span>
          </li>
        </ul>
        <Button variant="primary" className="w-100" href="/plans">Sign up free</Button>
      </Card.Body>
    </Card>
  );
}

export default PlanCard;
