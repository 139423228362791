import { Button, Container } from "react-bootstrap";

const PageCallToAction = ({ messageContent }) => {
  return (
    <>
      <section className="text-center py-10 py-md-11 py-xl-12 py-xxxl-13">
        <Container className="py-10 py-md-11 py-xl-12 py-xxl-13">
          <h2 className="h1 mb-10 mb-md-11">{messageContent}</h2>
          <Button href="/plans" size="lg">
            Try free for 7 days
          </Button>
        </Container>
      </section>
    </>
  );
};

export default PageCallToAction;
