import React from "react";
import { Container } from "react-bootstrap";
import Logoheader from "../header/Logoheader";

const Comingsoon = () => {
  window.intercomSettings = {
    api_base: "https://api-iam.intercom.io",
    app_id: "nedui962",
  };
  // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/nedui962'
  (function () {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === "function") {
      ic("reattach_activator");
      ic("update", w.intercomSettings);
    } else {
      var d = document;
      var i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      var l = function () {
        var s = d.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = "https://widget.intercom.io/widget/nedui962";
        var x = d.getElementsByTagName("script")[0];
        x.parentNode.insertBefore(s, x);
      };
      if (document.readyState === "complete") {
        l();
      } else if (w.attachEvent) {
        w.attachEvent("onload", l);
      } else {
        w.addEventListener("load", l, false);
      }
    }
  })();
  return (
    <>
      <Logoheader />
      <section className="coming-soon text-center">
        <Container>
          <div className="info-soon">
            <h1 className="ft-bold mb-4 pt-5">Something Exciting is coming.</h1>
            <p>Supporting the elderly, their families, and their loved ones.</p>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Comingsoon;
