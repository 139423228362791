import { Button, Card } from "react-bootstrap";

const PlanCard = ({ planType, keyType }) => {
  const prices = {
    annual: [2.49, 4.16, 5.83],
    monthly: [3.99, 5.99, 7.99],
  }

  return (
    <Card className="border-0 shadow position-relative overflow-hidden">
      {planType === 'family' && <span className="card-ribbon fw-bold text-dark small text-center py-2 mb-0">Best value</span>}
      <Card.Body className="p-6 p-md-7">
        <h2 className="h5 mb-4 mb-md-5">
          {planType === 'single' ? (
            <span>Single</span>
          ) : planType === 'couple' ? (
            <span>Couple</span>
          ) : planType === 'family' ? (
            <span>Family</span>
          ) : null }
        </h2>
        <div className="fs-3 text-info fw-bold lh-sm mb-0">
          {planType === 'single' ? (
            <span>£{prices[keyType.toLowerCase()][0]}</span>
          ) : planType === 'couple' ? (
            <span>£{prices[keyType.toLowerCase()][1]}</span>
          ) : planType === 'family' ? (
            <span>£{prices[keyType.toLowerCase()][2]}</span>
          ) : null }
        </div>
        <div className="small text-dark mb-5 mb-md-6">per month</div>
        <ul className="list-unstyled list-checked text-dark mb-5 mb-md-6">
          <li className="list-item d-flex align-items-center mb-2">
            <i className="fa fa-solid fa-check text-success"></i>
            {planType === 'single' ? (
              <span className="ms-3">1 main user</span>
            ) : planType === 'couple' ? (
              <span className="ms-3">2 main users</span>
            ) : planType === 'family' ? (
              <span className="ms-3">5 main users</span>
            ) : null }
          </li>
          <li className="list-item d-flex align-items-center mb-2">
            <i className="fa fa-solid fa-check text-success"></i>
            {planType === 'single' ? (
              <span className="ms-3">1 support user</span>
            ) : planType === 'couple' ? (
              <span className="ms-3">2 support users</span>
            ) : planType === 'family' ? (
              <span className="ms-3">5 support users</span>
            ) : null }
          </li>
          <li className="list-item d-flex align-items-center mb-2">
            <i className="fa fa-solid fa-check text-success"></i>
            <span className="ms-3">Cancel any time</span>
          </li>
          <li className="list-item d-flex align-items-center">
            <i className="fa fa-solid fa-check text-success"></i>
            <span className="ms-3">No hidden costs</span>
          </li>
        </ul>
        <Button variant="primary" className="w-100" href="/plans">Sign up free</Button>
      </Card.Body>
    </Card>
  );
}

export default PlanCard;
