import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Image } from "react-bootstrap";
import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import Loader from "../../loader/Loader";
import sonCookingWithElderlyFather from "../../../assets/images/index/son-cooking-with-elderly-father.jpg";
import screenie1 from "../../../assets/images/index/screenshots/screenie-1.jpg";
import screenie2 from "../../../assets/images/index/screenshots/screenie-2.jpg";
import screenie3 from "../../../assets/images/index/screenshots/screenie-3.jpg";
import grandMotherAndGrandson from "../../../assets/images/index/grandmother-and-grandson.jpg";
import quoteZoneLogo from "../../../assets/images/third-party-logos/quote-zone-logo.png";
import giftCard1 from "../../../assets/images/gift-cards/life-style-gift-card.jpg";
import giftCard2 from "../../../assets/images/gift-cards/john-lewis-gift-card.jpg";
import giftCard3 from "../../../assets/images/gift-cards/river-island-gift-card.jpg";
import giftCard4 from "../../../assets/images/gift-cards/one4all-gift-card.jpg";
import giftCard5 from "../../../assets/images/gift-cards/boots-gift-card.jpg";
import giftCard6 from "../../../assets/images/gift-cards/m-and-s-gift-card.jpg";
import UspSection from "../../templates/UspSection";
import PricingPlanCallToAction from "../../templates/PricingPlanCallToAction";
import PageCallToAction from "../PageCallToAction";
import TestimonialsSlider from "../../TestimonialsSlider/TestimonialsSlider";

const HowItWorks = () => {
  const [loading, setloading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setloading(false);
    }, 500);
  }, []);

  return (
    <>
      <Header />
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="hero">
            <Container>
              <Row className="justify-content-between gy-12 gy-md-13 gx-xxl-0">
                <Col xl="13" xxl="12" xxxl="10">
                  <h1 className="h2 text-center text-xl-start mb-5 mb-md-6 pt-xl-10 pt-xxxl-11">
                    Our mission, straight from the heart
                  </h1>
                  <p className="lead text-center text-xl-start mb-6 mb-md-7">
                    Hey there! We all know how rockstar our elderly folks are.
                    They’ve spun tales that shaped our world, and as they hit
                    their prime years, it’s time we lend a hand to ensure
                    they’ve got all they need.
                  </p>
                  <div className="text-center text-xl-start">
                    <Button href="/plans" size="lg">
                      Sign up free
                    </Button>
                  </div>
                </Col>
                <Col xl="10" xxl="11" className="offset-xl-1">
                  <Image
                    src={sonCookingWithElderlyFather}
                    className="img-object-fit-cover rounded-4"
                    alt="Son cooking with elderly father"
                  />
                </Col>
              </Row>
            </Container>
          </div>
          <Container>
            <section className="pb-11 pb-md-12 pt-xl-1 pb-xl-13 pt-xxxl-11 pb-xxxl-14">
              <Row className="justify-content-center mb-10 mb-md-11 mb-xl-12 mb-xxxl-13">
                <Col xl="20" xxxl="16">
                  <h2 className="text-center mb-5 mb-md-6">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </h2>
                  <p className="lead text-center mb-0">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nunc lobortis bibendum euismod. Phasellus ullamcorper
                    rhoncus vestibulum.
                  </p>
                </Col>
              </Row>
              <Row className="align-items-center gy-5 gy-md-7 gy-xl-0 pb-10 pb-md-11 pb-xl-12 pb-xxxl-13">
                <Col>
                  <Image
                    src={sonCookingWithElderlyFather}
                    className="img-object-fit-cover rounded-4"
                    alt="Son cooking with elderly father"
                  />
                </Col>
                <Col
                  xl="14"
                  xxl="13"
                  xxxl="12"
                  className="offset-xl-1 offset-xxl-2"
                >
                  <h3 className="mb-5">The lowdown</h3>
                  <p className="lead mb-0">
                    In the UK, we have a whopping 13 million people aged 65 and
                    up, making up 20% of our crew. And by 2040? We’re looking at
                    a rise to 18 million! But here’s the kicker: loads of these
                    incredible individuals are living life solo, and too many
                    are battling to make ends meet. We need to turn that around.
                  </p>
                </Col>
              </Row>
              <Row className="align-items-center gy-5 gy-md-7 gy-xl-0 pb-10 pb-md-11 pb-xl-12 pb-xxxl-13">
                <Col className="order-xl-last offset-xl-1 offset-xxl-2">
                  <Image
                    src={sonCookingWithElderlyFather}
                    className="img-object-fit-cover rounded-4"
                    alt="Son cooking with elderly father"
                  />
                </Col>
                <Col xl="14" xxl="13" xxxl="12">
                  <h3 className="mb-5">Enter our app</h3>
                  <p className="lead mb-0">
                    This isn’t just another app. It’s like a digital helping
                    hand from your family or friends. Created with a whole lot
                    of heart, it’s here to be a game-changer. Whether it’s the
                    elderly using it themselves or their loved ones jumping in
                    to lend a hand, it’s all about staying connected, getting
                    organised, and making that money stretch further.
                  </p>
                </Col>
              </Row>
              <Row className="align-items-center gy-5 gy-md-7 gy-xl-0 pb-10 pb-md-11 pb-xl-12 pb-xxxl-13">
                <Col>
                  <Image
                    src={sonCookingWithElderlyFather}
                    className="img-object-fit-cover rounded-4"
                    alt="Son cooking with elderly father"
                  />
                </Col>
                <Col
                  xl="14"
                  xxl="13"
                  xxxl="12"
                  className="offset-xl-1 offset-xxl-2"
                >
                  <h3 className="mb-5">
                    Family, friends, and the app - the dream team
                  </h3>
                  <p className="lead mb-0">
                    Our App is like having your family or friends in your
                    pocket. They can jump in, offer a hand or keep an eye out to
                    ensure everything’s ticking along nicely. Need to track
                    expenses? Check, Sort out upcoming events. Done. It’s like
                    having your team on standby, all through the App.
                  </p>
                </Col>
              </Row>
              <section className="pb-10 pb-md-11 pb-xl-12 pb-xxxl-13">
                <Row className="justify-content-center mb-9 mb-md-10 mb-xl-11">
                  <Col xl="20">
                    <h3 className="text-center mb-4 mb-md-5">
                      Making money less… well, tricky
                    </h3>
                    <p className="lead text-center mb-0">
                      Times are tight, and we want to ensure our elderly aren’t
                      feeling the squeeze. So, we’ve set up our App to be that
                      go-to place for:
                    </p>
                  </Col>
                </Row>
                <Row className="row-cols-1 row-cols-md-2 row-cols-xl-4 justify-content-center gy-5 gy-md-7 gy-xl-0 gx-xxxl-6">
                  <Col>
                    <div className="bg-primary-light rounded-3 p-6 p-xxxl-7 h-100">
                      <Row className="justify-content-center mb-7">
                        <Col xs="16" xl="19" xxxl="18">
                          <h4 className="h6 text-center mb-0">
                            Keeping tabs on all your expenses
                          </h4>
                        </Col>
                      </Row>
                      <Image
                        src={screenie1}
                        className="img-fluid rounded-3 shadow"
                        alt=""
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="bg-primary-light rounded-3 p-6 p-xxxl-7 h-100">
                      <Row className="justify-content-center mb-7">
                        <Col xs="18" xl="21" xxxl="18">
                          <h4 className="h6 text-center mb-0">
                            Comparing best prices through the comparison portal
                          </h4>
                        </Col>
                      </Row>
                      <Image
                        src={screenie2}
                        className="img-fluid rounded-3 shadow mb-3"
                        alt=""
                      />
                      <div className="d-flex justify-content-center align-items-center">
                        <div className="small-xs fw-semibold text-center">
                          Powered by
                        </div>
                        <Image
                          src={quoteZoneLogo}
                          height="16"
                          className="ms-2"
                          alt=""
                        />
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="bg-primary-light rounded-3 p-6 p-xxxl-7 h-100">
                      <Row className="justify-content-center mb-7">
                        <Col xs="18" xl xxxl="19">
                          <h4 className="h6 text-center mb-0">
                            Hacks to trim down bills and grab affordable
                            essentials
                          </h4>
                        </Col>
                      </Row>
                      <div className="me-9 mb-5">
                        <Image
                          src={screenie3}
                          className="img-fluid rounded-3 shadow"
                          alt=""
                        />
                      </div>
                      <div className="ms-9 mt-4">
                        <Image
                          src={grandMotherAndGrandson}
                          className="img-fluid rounded-3 shadow"
                          alt=""
                        />
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="bg-primary-light rounded-3 p-6 p-xxxl-7 h-100">
                      <Row className="justify-content-center mb-7">
                        <Col xs="18" xl xxxl="19">
                          <h4 className="h6 text-center mb-0">
                            Utilise your vouchers and coupons
                          </h4>
                        </Col>
                      </Row>
                      <Row className="justify-content-center gy-5 gx-xxxl-6 gy-xxxl-6">
                        <Col xs="auto">
                          <Image
                            src={giftCard1}
                            className="img-gift-card shadow rounded-1"
                            alt=""
                          />
                        </Col>
                        <Col xs="auto">
                          <Image
                            src={giftCard2}
                            className="img-gift-card shadow rounded-1"
                            alt=""
                          />
                        </Col>
                        <Col xs="auto">
                          <Image
                            src={giftCard3}
                            className="img-gift-card shadow rounded-1"
                            alt=""
                          />
                        </Col>
                        <Col xs="auto">
                          <Image
                            src={giftCard4}
                            className="img-gift-card shadow rounded-1"
                            alt=""
                          />
                        </Col>
                        <Col xs="auto">
                          <Image
                            src={giftCard5}
                            className="img-gift-card shadow rounded-1"
                            alt=""
                          />
                        </Col>
                        <Col xs="auto">
                          <Image
                            src={giftCard6}
                            className="img-gift-card shadow rounded-1"
                            alt=""
                          />
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </section>
              <Row className="align-items-center gy-5 gy-md-7 gy-xl-0 pb-10 pb-md-11 pb-xl-12 pb-xxxl-13">
                <Col className="order-xl-last offset-xl-1 offset-xxl-2">
                  <Image
                    src={sonCookingWithElderlyFather}
                    className="img-object-fit-cover rounded-4"
                    alt="Son cooking with elderly father"
                  />
                </Col>
                <Col xl="14" xxl="13" xxxl="12">
                  <h3 className="mb-5">Never miss a beat</h3>
                  <p className="lead ">
                    Juggling life can get hectic, and sometimes, things slip
                    through the cracks. That’s where our App steps in:
                  </p>
                  <ul className="list-unstyled list-checked lead mb-0">
                    <li className="list-item d-flex mb-3">
                      <i className="fa fa-solid fa-check text-success lh-base"></i>
                      <span class="ms-5">
                        The reminder panel lets you customise when you want to
                        receive a reminder for all your needs.
                      </span>
                    </li>
                    <li className="list-item d-flex mb-3">
                      <i className="fa fa-solid fa-check text-success lh-base"></i>
                      <span class="ms-5">
                        Alerts allow you to know upcoming subscription renewals,
                        warranties for products you have, vouchers and gifts
                        cards you can use.
                      </span>
                    </li>
                    <li className="list-item d-flex mb-3">
                      <i className="fa fa-solid fa-check text-success lh-base"></i>
                      <span class="ms-5">
                        The supporting concept allows you to have family,
                        friends supporting with your to do’s such as picking up
                        a prescription or taking you to an appointment.
                      </span>
                    </li>
                    <li className="list-item d-flex">
                      <i className="fa fa-solid fa-check text-success lh-base"></i>
                      <span class="ms-5">
                        All those little things you want to remember, all
                        sorted.
                      </span>
                    </li>
                  </ul>
                </Col>
              </Row>
              <Row className="align-items-center gy-5 gy-md-7 gy-xl-0">
                <Col>
                  <Image
                    src={sonCookingWithElderlyFather}
                    className="img-object-fit-cover rounded-4"
                    alt="Son cooking with elderly father"
                  />
                </Col>
                <Col
                  xl="14"
                  xxl="13"
                  xxxl="12"
                  className="offset-xl-1 offset-xxl-2"
                >
                  <h3 className="mb-5">The nitty-gritty</h3>
                  <p className="lead mb-0">
                    In this mad world, we’re rooting for connections and
                    memories and ensuring our roots — our elders — have the
                    smoothest ride. If your Elderly and need to get organised,
                    planned and save money or you want to support a parent,
                    grandparent or a loved one - With our App,, it’s about
                    making every day brighter and more sorted. Let’s dive in and
                    let us Make Life Simple.
                  </p>
                </Col>
              </Row>
            </section>
            <UspSection />
          </Container>
          <TestimonialsSlider />
          <PricingPlanCallToAction />
          <PageCallToAction messageContent="Make life more simple today." />
          <Footer />
        </>
      )}
    </>
  );
};

export default HowItWorks;
